// import Immutable from 'immutable';

const variables = {
  siteWidthMax: '1400px',
  contentWidthMax: '1140px',
  screen: {
    xs: '768px',
    sm: '768px',
    md: '992px',
    tablet: '1024px',
    lg: '1200px',
  },
  fontFamily: {
    sansSerif: "'proxima-nova', arial, sans-serif",
    body: "'proxima-nova', arial, sans-serif",
    display: 'brandon-grotesque, sans-serif',
    serif: 'georgia, serif',
    cta: "'Helvetica Neue', Helvetica, Arial, sans-serif",
    text: "'PT Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
    block: "'akzidenz-grotesk-extended', 'Arial Black', sans-serif",
    title: "'museo-slab', georgia, serif",
    serifFont: 'georgia, serif',
    titleFont: "'akzidenz-grotesk-extended', 'Arial Black', sans - serif",
    mono: 'monospace, courier',
  },
  path: {
    iconFont: '/assets/',
    cloudfront: 'https://d2q9bdd302n972.cloudfront.net/assets',
    cloudfrontBase: 'https://d1m2xmyf58uf17.cloudfront.net',
    s3: 'http://mvp.larktravel.com.s3.amazonaws.com/assets',
  },
  whiteSpace: {
    small: '0.25rem',
    medium: '0.5rem',
    normal: '0.75rem',
    large: '1rem',
  },
  borderRadius: {
    small: '0.25rem',
    medium: '0.5rem',
    large: '1rem',
    form: '4px',
  },
  cloudfrontUrl: 'https://d2q9bdd302n972.cloudfront.net/assets',
  aspectRatio: {
    square: 'padding-top: 100%',
    standard: 'padding-top: 75%',
    wide: 'padding-top: 66.66%',
    theatre: 'padding-top: 52.25%',
    cinema: 'padding-top: 42%',
    inherit: 'padding-top: inherit',
  }
};

export default variables;
