import Color from 'color';

export { default as colors } from './colors';
export { default as variables } from './variables';

export const toRgba = (color, alpha) =>
  Color(color)
    .alpha(alpha)
    .string();

export const lighten = (color, pct) =>
  Color(color)
    .lighten(1 - pct)
    .string();

export const darken = (color, pct) =>
  Color(color)
    .darken(pct)
    .string();
