// import Immutable from 'immutable';

const colors = {
  black: '#000000',
  dimBlack: '#757575',
  darkGray: '#808080',
  coolGray: '#d1d3d9',
  warmGray: '#e5e6e6',
  paleGray: '#fafafa',
  sideBarLtGray: '#f2f2f2',
  sideBarMdGray: '#d8d8d8',
  white: '#fff',

  red: '#e66756',
  blue: '#6f9ece',
  green: '#31b482',
  peach: '#ffa78e',
  yellow: '#fcf113',

  primaryBrandColor: '#000',
  posGreen: '#57a55c',
  negRed: '#e3545b',
  cautionYellow: '#ffea00',
  darkRed: '#dc2831',
  skylarkPurple: '#8237D9',
  warningOrange: '#ffae42',
  infoBlue: '#2bafff',

  selectionBlue: '#b4d5fe',
  linkBlue: '#2bafff',
  facebookBlue: '#3b5998',
  iconBlue: '#206ee8',

  highlightBackground: '#e9d6f4',
  infoBackground: '#d9edf7',
  errorBackground: '#f2dede',
};

export default colors;
